<template>
  <div
    v-show="itemList.length > 0"
    class="post-welfare-container"
  >
    <div @click="goToScoreShop">
      <span class="post-welfare-title-shop-icon" />
      <span class="post-welfare-title">
        {{ getTitle() }}
      </span>
      <span class="post-welfare-title-right-arrow" />
    </div>
    <div
      @click="goToScoreShop"
      class="post-welfare-items-container"
    >
      <img
        v-for="(item, index) in itemList.slice(0, maxItemCount)"
        :key="index"
        :src="item"
        class="post-welfare-item"
        alt="welfare"
      >
      <img
        v-if="itemList.length > maxItemCount"
        src="./img/more.png"
        class="post-welfare-item-more"
        alt="more"
      >
    </div>
  </div>
</template>

<script>
import { getContentShareGifts } from '@/api/welfare';
import { openView } from '@/jsbridge';
import launchApp from '@/util/launchApp';
import default1 from './img/default_1.png';
import default2 from './img/default_2.png';
import default3 from './img/default_3.png';
import default4 from './img/default_4.png';
import default5 from './img/default_5.png';
import { checkEnv } from '@/util/browser-env';

export default {
  name: 'PostWelfareV2',
  props: {
    gameCode: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      env: checkEnv(),
      maxItemCount: 5,
      isDefault: false,
      itemList: [],
      defaultItems: [default1, default2, default3, default4, default5, 'show_more'],
    };
  },
  mounted() {
    this.fetchGifts();
  },
  methods: {
    fetchGifts() {
      getContentShareGifts(this.gameCode).then((result) => {
        if (result.ret === 0 && result.giftItems?.length) {
          result.giftItems.forEach((item) => {
            this.itemList.push(item.url);
          });
        } else {
          this.useDefaultGifts();
        }
      })
        .catch(this.useDefaultGifts);
    },
    useDefaultGifts() {
      this.itemList = this.defaultItems;
      this.isDefault = true;
    },
    goToScoreShop() {
      const pointStoreUrl = `tgc://native?moduleName=pointsmall&initGameCode=${this.gameCode}`;
      if (this.env.isApp) {
        openView({
          options: pointStoreUrl,
        });
      } else {
        launchApp({
          scheme: pointStoreUrl,
        });
      }
    },
    getTitle() {
      if (this.isDefault) {
        return '超过800个礼包等你来领取';
      }
      return '前往积分商城领取更多福利';
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss" />
